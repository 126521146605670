import '../stylesheets/application.scss';

const resizeDelay = 300;
let triggered = false;

let nav = null;

document.addEventListener('DOMContentLoaded', function() {
    nav = document.querySelector('.footer__nav');

    fixFooterNav();

    window.addEventListener('resize', function() {
        if (!triggered) {
            triggered = true;
            setTimeout(function() {
                if (triggered) {
                    triggered = false;
                    fixFooterNav();
                }
            }, resizeDelay);
        }
    }, true);
});

function fixFooterNav() {
    if (!nav) return;

    const total = nav.getBoundingClientRect().width;
    let curr = 0;
    let prevItem = null;

    for (const item of nav.children) {
        const width = item.getBoundingClientRect().width;
        curr += width;

        if (isFooterSep(item)) {
            item.style.display = 'block';
        }

        if (curr >= total) {
            if (isFooterSep(item)) {
                item.style.display = 'none';
                curr = 0;
            } else if (isFooterSep(prevItem))
            {
                prevItem.style.display = 'none';
                curr = item.offsetWidth;
            }
        }

        prevItem = item;
    }
}

function isFooterSep(item)
{
    return item && item.classList.contains('footer__nav-link-sep');
}
